import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  onMessage({ data }) {
    switch (data.type) {
      case "height":
        this.element.style.setProperty("height", `${data.data}px`)
        break
      case "scrollToTop":
        // What Inbest does
        // const rect = this.element.getBoundingClientRect()
        // window.scrollTo(0, rect.top + window.scrollY - 90 // WTF is the -90 for?

        // What I think they actually want
        this.element.scrollIntoView(true)
        break
    }
  }
}
