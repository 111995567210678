import { Controller } from "@hotwired/stimulus"
import lottie from "lottie-web"

export default class extends Controller {
  static targets = ["container"]

  initialize() {
    this.containerTargets.forEach((element) => {
      element.innerHTML = ""
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw1"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-1.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw1-mob"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-1.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw2"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-2.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw2-mob"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-2.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw3"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-3.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw3-mob"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-3.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw4"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-4.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })

    lottie.loadAnimation({
      container: document.getElementById("hiw4-mob"), // Required
      renderer: "svg", // Required
      path: "/animations/hiw-4.json", // Required
      loop: true, // Optional
      autoplay: true, // Optional
    })
  }
}
