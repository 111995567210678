import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]
  static values = {
    offset: Number,
  }

  animate() {
    var y = window.scrollY
    const h = 700
    var offset = this.offsetValue

    this.imageTargets.forEach((element, index) => {
      let number = index + 1
      let final = this.imageTargets.length

      let start = offset + h * (number - 1) - 200
      let stop = offset + h * (number - 1) + h * 0
      let fade = offset + h * (number - 1) + h * 0.6
      let dis = offset + h * (number - 1) + h - 50

      if (y >= start && y <= stop && number != 1) {
        element.style.transform = "scale(" + (y - start) / (stop - start) + ")"
      } else if (y <= stop && number == 1) {
        element.style.transform = "scale(1)"
      } else if (y >= stop && y <= fade) {
        element.style.transform = "scale(1)"
      } else if (y >= fade && y <= dis && number != final) {
        element.style.transform = "scale(" + (1 - (y - fade) / (dis - fade)) + ")"
      } else if ((y >= fade) & (number == final)) {
        element.style.transform = "scale(1)"
      } else {
        element.style.transform = "scale(0)"
      }
    })
  }
}
